<template>
  <div class="qianzhang ">
    <div class="border ">
      <!-- 头部的按钮 -->
      <div class="btns">
        <span class="left">我的签章</span>
        <div class="btn">
          <div @click="apply" class="right">申请签章</div>
          <div @click="click" class="right">获取签章</div>
        </div>
      </div>
      <el-table
        class="customer-no-border-table"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
      >
        <el-table-column prop="seal_name" label="印章名称"> </el-table-column>
         <el-table-column prop="seal_type" label="印章类型">
          <template slot-scope="scope">
            <div v-if="scope.row.seal_type == 1">公章</div>
            <div v-if="scope.row.seal_type == 2">个人名章</div>
               <div v-if="scope.row.seal_type == 3">全部印章</div>
          </template>
        </el-table-column>
        <el-table-column prop="seal_code" label="印章赋码"> </el-table-column>
         <el-table-column prop="seal_format" width="120" label="印章格式">
          <template slot-scope="scope">
            <div v-if="scope.row.seal_format == 1">图片印章</div>
            <div v-if="scope.row.seal_format == 2">国密标准电子印章</div>
          </template>
        </el-table-column>
       <el-table-column label="印章图片">
          <template slot-scope="scope">
            <!-- {{scope.row.seal_data_img}} -->
            <img
            style="padding-top:10px"
              :src="'data:image/jpg;base64,' + scope.row.seal_data_img"
              alt=""
              height="70"
              width="70"
            />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="印章有效期"> </el-table-column> -->
        <el-table-column prop="address" label="操作" width="210">
          <!-- <template slot-scope="{ row }"> -->
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="checkSign(scope.row)"
              >查看签章</el-button
            >
            <!-- <el-button type="danger" icon="el-icon-delete" size="small"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="签章图片" :visible.sync="dialogVisible" width="40%">
      <div class="preview-img">
        <img :src="'data:image/jpg;base64,' + signSrc" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSealComposite, getLoginUrl } from "@/api/userCenters.js";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      signSrc: "",
      isClick: false,
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/qianzhang", title: "我的签章" },
      ],
      isTest: true,
    };
  },
  methods: {
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;

      if (status == 0) {
        ESASignClient.ESAVerifyPin(_this.JsESAVerifyPin, null, false);
      } else {
        alert(msg);
      }
    },
    JsESAVerifyPin(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        getSealComposite().then((res) => {
          if (res.code == 0) {
            _this.tableData = res.data;
          } else {
            if (_this.isClick) {
              _this.$YTmsg(
                res.message + ",请核对Ukey和当前登录的用户信息是否一致!",
                "error"
              );
            }
          }
        });
      } else {
        alert(msg);
      }
    },
    checkSign(row) {
      if (!this.beforhandle('personalCenter:mySignature:view')) return;
      console.log(row);
      this.signSrc = row.seal_data_img;
      this.dialogVisible = true;
    },
    apply() {
      if (!this.beforhandle('personalCenter:mySignature:apply')) return;
      getLoginUrl().then((res) => {
        if (res.code == 0) {
          window.open(res.data, "_blank");
        }
      });
    },
    click() {
      if (!this.beforhandle('personalCenter:mySignature:get')) return;
      this.getList();
      this.isClick = true;
    },
    getList() {
        getSealComposite().then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.tableData =[]
          }
        });
      // ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
    },
    // 表头样式
    headerCellStyle() {
      return "background-color:#F7F7F7;color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;";
    },
    cellStyle(column) {
      if (column.columnIndex == 0) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      } else if (column.columnIndex == 1) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      } else {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      }
    },
  },
  computed: {},
  created() {},
  mounted() {
     this.getList();
  },
  watch: {},
  components: {},
};
</script>

<style lang="scss">
.preview-img {
  text-align: center;
}
.el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}

.el-table__header {
  border: none;
}

.qianzhang {
  // width: 1920px;
  margin-top: 20px;
  height: 955px;
  background: #f7f7f7;
}

.header {
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.border {
  height: 662px;
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
  .btns {
    height: 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 14px;
    background: #f3f3f3;
    border: 1px solid #e5e5e5;
    .left {
      width: 80px;
      height: 17px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 17px;
    }
    .btn {
      display: flex;
      div:first-child {
        margin-right: 30px;
      }
      .right {
        width: 193px;
        height: 34px;
        background: linear-gradient(90deg, #2079fb, #4cb2fc);
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 9px;
        text-align: center;
        line-height: 34px;
      }
    }
  }
  .table {
    margin: 26px 23px 25px 21px;
    height: 564px;
  }
}

.el-table {
  width: 930px;
  height: 565px;
  margin: 20px;
  margin-bottom: 0px;
  border: 1px solid #e5e5e5;
}

.el-table__row {
  height: 60px !important;
}
</style>
